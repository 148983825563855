.next-btn, .prev-btn {
    position: fixed;
    width: 37px;
    height: 62px;
    top: calc(50% - 31px);
    left: 20px;
    z-index: 1;
    background: #00000000 no-repeat center;
    background-image: url(../img/left.png);
    color: #000;
    border: none;
    font-size: 3em;
    line-height: 50px;
}
.next-btn {
    background-image: url(../img/right.png);
    left: unset;
    right: 20px;
}

.timeline {
    --center: 670px;
    .bg-lines {
        position: absolute;
        left: 0;
        top: var(--center);
        width: 100vw;

        &:before, &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 67px;
            top: -32px;
            background-color: var(--accent2);
            // filter:brightness(1.2);
        }
        &:after {
            top: -2px;
            height: 9px;
            background-color: var(--accent3);
            // filter:brightness(.8);
        }
    }
    .layers {
        overflow-x: clip;
        overflow-y: visible;

        img.image-layer {
            position: absolute;
            left: 0;
            top: calc(var(--center) * -1);
            width: 100vw;
            height: 100vh;
            animation: fadein .5s;
            animation-fill-mode: both;
            animation-delay: calc(.5s + (.1s * var(--index)));
            pointer-events: none;
        }
    // }
    // .milestones {
        position: absolute;
        top: var(--center);
        width: 100vw;

        h4, h5, h6 {
            margin: 0;
            margin-top: -.25em;
            line-height: .6;
            font-size: 83px;
            letter-spacing: .1em;
            padding-left: 0.25em;
            text-transform: uppercase;
            color: var(--txtcolor);
            position: relative;
        }
        h5, h6 {
            font-size: 20px;
            text-transform: none;
        }
        h5 {
            margin-left: 750px;
            margin-top: -43px;
        }
        h6 {
            margin-left: 930px;
            margin-top: 13px;
        }
        span {
            color: var(--txtcolor);
            position: absolute;
            font-size: 40px;
            right: 1em;
            top: -.5em;
        }
        h4, span {
            transition: transform .3s;
            transform: translateX( calc(var(--drag) * 1px) );
        }
        .timeline-milestone  {
            position: absolute;
            filter: drop-shadow(2px 2px 4px #00000066);
            div {
                width: 60px;
                padding-left: 10px;
                padding-right: 10px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: left;
                border: 4px solid #fff;
                background-color: var(--accent1);
                margin-left: -44px;
                font-weight: 700;
                font-size: 18px;
                margin-top: calc(var(--top) - 44px);
                animation: slideup .5s;
                animation-fill-mode: both;
                animation-delay: calc(.1s * var(--index));
                color: var(--txtcolor);
            }
            p {
                position: absolute;
                width: 140px;
                height: 80px;
                border: 4px solid #fff;
                background-color: #fff;
                color: #000;
                line-height: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                // text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                top: calc(var(--top) - 44px);
                margin: 0;

                &.left {
                    left: 40px;
                }
                &.left-wide {
                    left: 40px;
                    width: 200px;
                }
                &.left-offset {
                    left: 40px;
                    width: 200px;
                }
                &.right {
                    text-align: right;
                    left: calc(-140px - 48px);
                }
                &.right-wide {
                    text-align: right;
                    width: 200px;
                    left: calc(-200px - 48px);
                }

                animation: fadein .5s;
                animation-fill-mode: both;
                animation-delay: calc(calc(.1s * var(--index)) + .5s);
            }
            &:after {
                content: "";
                position: absolute;
                width: 4px;
                left: -2px;
                top: calc(2px + calc(var(--direction) * 5px));
                height: var(--len);
                transform-origin: 0 0 0;
                background-color: var(--accent0);
                animation: drawin .5s;
                animation-fill-mode: both;
                animation-delay: calc(.1s * var(--index));
            }
            &.out {
                div, p, &:after {
                    animation: none;
                }
                &:after {
                    transform: scaleY(var(--direction));
                }
            }

            &.inactive {
                --accent1: #808080;
                --accent0: #808080;
                --txtcolor: #ffffff;
            }
        }
    }
    &:has(~ .modal){
        // opacity: 0;
        pointer-events: none;
    }

    button.close-btn {
        right: unset;
        left: 0;
    }

    &:has(.timeline-intro){
        button.close-btn {
            left: unset;
            right: 0;
            background-image: url(../img/close.png);
        }
    }
}

.timeline .layers.moving-1 {
    .this-year { animation: slidein-fromright .5s both; }
    .prev-year, .image-layer.out, .timeline-milestone.out { animation: slideout-toleft .5s both; }
}
.timeline .layers.moving--1 {
    .this-year { animation: slidein-fromleft .5s both; }
    .prev-year, .image-layer.out, .timeline-milestone.out { animation: slideout-toright .5s both; }
}

@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes slideup {
    0% {
        opacity: 0;
        margin-top: calc(-44px + calc(var(--direction) * 44px));
    }
    100% {
        opacity: 1;
        margin-top: calc(var(--top) - 44px);
    }
}
@keyframes drawin {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
    100% {
        opacity: 1;
        transform: scaleY(var(--direction));
    }
}
@keyframes slidein-fromright {
    0% { transform: translateX(100vw); }
    100% { transform: translateX(0); }
}
@keyframes slideout-toleft {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100vw); }
}
@keyframes slidein-fromleft {
    0% { transform: translateX(-100vw); }
    100% { transform: translateX(0); }
}
@keyframes slideout-toright {
    0% { transform: translateX(0); }
    100% { transform: translateX(100vw); }
}

.timeline-intro {
    position: fixed;
    // padding: 64px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // background-color: #fff;
    color: #000;

    display: flex;
    // gap: .75em;
    
    > div:first-child {
        padding-bottom: 148px;
        // background-color: #999;
        img {
            border-right: 30px solid var(--accent0);
            width: 535px;
            object-fit: contain;
            object-position: bottom;
            margin-left: 0;
            height: 100%;
            // padding-bottom: 140px;
            // border-bottom: 140px solid #999999;
            animation: slide-down .5s;
        }
    }
    > div:last-child {
        // margin-bottom: 140px;
        // display: flex;
        // flex-direction: column;
        // justify-content: flex-start;
        // align-items: flex-start;
        position: relative;
        background: #fff;
        flex-grow: 1;
        padding-left: 70px;
        padding-top: 200px;
        > * {
            margin: 0;
        }
        animation: slidein-fromright 0.5s;

        h2 {
            // position: absolute;
            // bottom: 650px;
            border-bottom: 3px solid var(--accent0);
            text-transform: uppercase;
            font-size: 100px;
            letter-spacing: .1em;
            display: inline-block;
            margin-bottom: 70px;
        }
        h3 {
            margin-bottom: 20px;
            font-size: 83px;
        }
        p {
            font-weight: 400;
            font-size: 24px;
            width: 1060px;

            small {
                font-size: 15px;
                margin-top: 1em;
                display: block;
                padding-right: 100px;
                background-image: url(../img/qrcode.png);
                background-repeat: no-repeat;
                background-position: right center;
                background-size: contain;
            }
            
            &.credits {
                position: absolute;
                bottom: 148px;
                font-size: 8px;
                span { display: block; font-weight: 600; margin-bottom: 1em; font-size: 10px; }
            }
        }
    }
}

@keyframes slide-down {
    0% { transform: translateY(-100vh); }
    100% { transform: translateY(0); }
}