.modal {
    position: fixed;
    // padding: 64px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    z-index: 9999;
    color: #000;
    > img {
        width: 100%;
        height: 720px;
        // border: 32px solid #fff;
        box-sizing: border-box;
        object-fit: contain;
        background: #fff;
        display: block;
        animation: modal-slide-down .5s;
    }

    // year
    > em {
        display: block;
        // border-top: 45px solid var(--accent);
        position: absolute;
        color: var(--accent);
        font-weight: 100;
        font-size: 166px;
        font-style: normal;
        line-height: .75;
        left: 0;
        top: 0;
        width: 650px;
        padding-left: 105px;
        padding-top: 45px;
        animation: modal-slide-down .5s .1s both;
        &:before {
            display: block;
            content: '';
            width: 100vw;
            position: absolute;
            height: 45px;
            background-color: var(--accent);
            top: 0;
            left: 0;
        }

        &.long:has(+ aside) {
            font-size: 88px;
        }
        &.long:not(:has(+ aside)) {
            word-spacing: 650px;
        }
    }

    // tabs
    ul { 
        padding: 0 0 0 116px; margin: 0; 
        // background: #fff;
        border-bottom: 16px solid #bfbfbf;
        margin-top: -48px;
        li {
            list-style: none;
            padding: 10px;
            background: #ddd;
            display: inline-block;
            padding: 16px 24px 8px;
            margin-right: 2px;
            font-size: 20px;
            font-weight: 400;
            // margin-top: -48px;
            position: relative;


            &.selected {
                background: #bfbfbf;
                color: #000;
                font-weight: 600;
            }
        }
        animation: modal-slide-up .5s;
    }

    // content panel
    > div {
        background: #efedee;
        flex-grow: 1;
        border-left: 116px solid var(--accent);
        // border-right: 32px solid #000;
        display: flex;
        flex-direction: row;
        padding: 16px;
        color: #000;
        position: relative;
        > div {
            padding-right: 48px;
            flex-basis: 730px;
            flex-shrink: 0;
            h3 {
                font-weight: 600;
                font-size: 40px;
                // font-style: italic;
                line-height: 1em;
                border-bottom: 2px solid #000;
                padding-bottom: 8px;
                margin: 0 0 8px;
                min-height: 190px;
                small {
                    font-size: 40px;
                    font-style: normal;
                    display: block;
                    line-height: 1;
                }
                em { display: block; }
            }
            span {
                display: block;
                font-size: 18px;
                opacity: .5;
            }
            > small {
                display: block;
                font-size: 13px;
                margin-top: 6px;
            }
            border-right: 2px solid var(--accent);
        }
        p {
            flex-grow: 1;
            font-size: 20px;
            padding-left: 24px;
            padding-right: 60px;
            line-height: 1.2;
            margin: 0;
        }
        animation: modal-slide-up .5s;
    }

    button.close-btn {
        left: unset;
        right: 0;
        background-image: url(../img/close.png);
    }

    aside {
        position: absolute;
        width: 600px;
        height: 455px;
        left: 116px;
        top: 190px;
        display: flex;
        img { 
            flex: 0 0 320px;
            max-width: 320px;
            object-fit: cover;
        }
        div {
            display: flex;
            flex-direction: column;
            background-color: #cddfb8;
            h4 {
                color: var(--accent);
                border-bottom: 1px solid var(--accent);
                margin: .5em 0 .25em;
                font-size: 24px;
                font-weight: 600;
                text-align: center;
                padding-bottom: 10px;
            }
            p {
                flex-grow: 1;
                font-size: 18px;
                text-align: center;
                margin: 1em;
                small {
                    font-size: 13px;
                    text-align: left;
                    display: block;
                    position: absolute;
                    bottom: 1em;
                }
            }
            button {
                background-color: #aacd9a;
                color: #000;
                border: none;
                padding: 1em;
                text-align: center;
                font-size: 24px;
                font-weight: 600;
            }
        }

        animation: modal-slide-in .5s .25s both;
    }

    // year modal
    &:has(> em){
        > img {
            height: calc(720px - 45px);
            object-fit: contain;
            object-position: left bottom;
            padding-left: 760px;
            margin-top: 45px;
            background-image: url(../img/bg-green.png);
            background-size: 2096px auto;
            // animation: bg-shift 20s infinite ease-in-out alternate;
        }
        > div > div {
            flex-basis: 580px;
        }
        button.close-btn {
            top: 45px;
        }
    }
}

@keyframes modal-slide-down {
    0% { transform: translateY(-50vh); }
    100% { transform: translateY(0); }
}
@keyframes modal-slide-up {
    0% { transform: translateY(50vh); }
    100% { transform: translateY(0); }
}
@keyframes modal-slide-in {
    0% { transform: translateX(-50vw); }
    100% { transform: translateX(0); }
}
@keyframes bg-shift {
  0% { background-position-x: 0; }
  100% { background-position-x: -176px; }
}