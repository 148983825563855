@use 'css/variables';
@use 'css/fonts';

.App {
  background: #d9d9d9; // url('img/background.jpg');
  // background-image: url(img/bg1.png);
  min-height: 100vh;
  user-select: none;

  font-family: 'Graphik';
  font-size: calc(10px + 2vmin);
  color: #333;

  .background {
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    img {
      position: absolute;

      &.layer1 {
        opacity: .09;
        mix-blend-mode: hard-light;
        animation: left-right 60s infinite ease-in-out alternate;
      }
      &.layer2 {
        opacity: .61;
        mix-blend-mode: color-dodge;
        animation: up-down 45s infinite ease-in-out alternate-reverse;
      }
      &.layer3 {
        opacity: .2;
        animation: up-down 30s infinite ease-in-out alternate;
      }
    }
  }
}
@keyframes up-down {
  0% { transform: translateY(0); }
  100% { transform: translateY(-433px); }
}
@keyframes left-right {
  0% { transform: translateX(0); }
  100% { transform: translateX(-168px); }
}

button.close-btn {
  border: none;
  position: fixed;
  right: 0px;
  top: 0px;
  background: #00008a00;
  background-image: url(./img/home.png);
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 0;
  color: #fff;
  font-family: 'Graphik';
  font-weight: 100;
  font-size: 50px;
  padding: 20px;
  z-index: 999;
  width: 86px;
  height: 86px;
  text-align: center;

  &:active {
    filter: invert(1) drop-shadow(0px 0px 5px #008a00);
  }
}

@import 'css/mainmenu';
@import 'css/artifacts';
@import 'css/timeline';
@import 'css/modal';