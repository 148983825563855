@font-face {
    font-family: 'Graphik';
    font-weight: 100;
    src: url('../font/TD Graphik-Semilight.otf');
}
@font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url('../font/TD Graphik-Regular.otf');
}
@font-face {
    font-family: 'Graphik';
    font-weight: 500;
    src: url('../font/TD Graphik-Medium.otf');
}
@font-face {
    font-family: 'Graphik';
    font-weight: 600;
    src: url('../font/TD Graphik-Semibold.otf');
}
@font-face {
    font-family: 'Graphik';
    font-weight: 700;
    src: url('../font/TD Graphik-Bold.otf');
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 100;
}